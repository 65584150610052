import debounce from 'debounce';
import {addClass, closest, hasClass, removeClass, trigger} from "@elements/dom-utils";
import {onFind, onInitInScope} from "@elements/init-modules-in-scope";

/*
    custom affix
    due to changes of offset
    setAffix() function is modified

    todo: move to package?
 */
let elements = [];
let currentElements = []; // all elements except for elements that are taller than the viewport
let matchesMediaQuery = true;
let lastScrollPosition = 0;
let isRunning = false;

const defaultOptions = {
    mediaQuery: null
};

const defaultSelectors = {
    base: '.js-affix',
    placeholder: '.js-affix__placeholder',
    container: '.js-affix__container'
};

export const EVENTS = {
    AFFIX: 'affix/affix',
    DETACH: 'affix/detach'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    matchesMediaQuery = options.mediaQuery ? matchMedia(options.mediaQuery).matches : true;

    options = {
        ...defaultOptions,
        ...options
    };

    if (options.mediaQuery) {
        // Use addListener instead of addEventListener because of IE
        matchMedia(options.mediaQuery).addListener(({matches}) => {
            matchesMediaQuery = matches;

            if (matches) {
                if (isRunning) {
                    requestAnimationFrame(interval)
                }
            } else {
                elements.forEach(resetElement);
            }
        });
    }

    window.addEventListener('resize', debounce(() => {
        currentElements = elements.filter(isElementTooBig);
        elements.forEach(resetElement);

        if (matchesMediaQuery) {
            elements.forEach(obj => {
                let elementRect = obj.element.getBoundingClientRect();
                let placeholderRect = obj.placeholder.getBoundingClientRect();
                let containerRect = obj.container.getBoundingClientRect();


                if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                    if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                        setAffixBottom(obj);
                    } else {
                        setAffix(obj);
                    }

                    obj.isAffix = true;
                }
            });
        }

        lastScrollPosition = Number.NEGATIVE_INFINITY;
        interval();
    }, 200));


    onFind(selectors.base, function (baseElement) {
        createAffix(
            baseElement,
            {options},
            {...defaultSelectors, ...selectors}
        );
    });

    onInitInScope(forceRecalculation);
}

export function createAffix(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    if (!matchesMediaQuery) {
        return;
    }
    let placeholder = closest(selectors.placeholder, baseElement);
    let container = closest(selectors.container, baseElement);

    baseElement.style.transform = 'translateZ(0)';

    if (!container) {
        console.warn('Could not find parent ".js-affix__container" for element ', baseElement);
        return null;
    }

    if (!placeholder) {
        console.warn('Could not find parent ".js-affix__placeholder" for element ', baseElement);
        return null;
    }

    if (placeholder) {
        placeholder.style.minHeight = baseElement.getBoundingClientRect().height.toString() + "px";
    }

    let elementObj = {
        element: baseElement,
        placeholder: placeholder,
        container: container,
        isAffix: false,
        isCentered: hasClass('js-affix--centered', baseElement),
        isBottom: hasClass('js-affix--bottom', baseElement),
        offset: +baseElement.getAttribute('data-affix-offset') || 0
    };

    elements = elements.concat(elementObj);
    // check if elements are in dom (so IE doesn't throw an error)
    elements = elements.filter(el => isElementInDom(el.element));

    currentElements = elements.filter(isElementTooBig);

    lastScrollPosition = 0;
    setAffixStates();

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

export function forceRecalculation(){
    lastScrollPosition = Number.NEGATIVE_INFINITY;

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    if (!matchesMediaQuery) {
        return;
    }

    if (lastScrollPosition === window.pageYOffset) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.pageYOffset;

    currentElements.forEach(function (obj) {
        let elementRect = obj.element.getBoundingClientRect();
        let placeholderRect = obj.placeholder.getBoundingClientRect();
        let containerRect = obj.container.getBoundingClientRect();

        if (obj.isAffix) {
            if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                setAffixBottom(obj);
            }
            if (!isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                resetElement(obj);
            }
        } else {
            if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom) && !isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
                setAffix(obj);

                obj.isAffix = true;
            }
        }
    });

    requestAnimationFrame(interval);
}

function resetElement(obj) {
    removeClass('is-affix', obj.element);
    trigger(EVENTS.DETACH, obj.element);

    Object.assign(obj.element.style, {
        position: '',
        top: '',
        left: '',
        width: '',
        transform: 'translateZ(0)',
    });

    if (obj.placeholder) {
        obj.placeholder.style.minHeight = obj.element.getBoundingClientRect().height.toString();
    }

    obj.isAffix = false;
}

function setAffixStates() {
    currentElements.forEach(function (obj) {
        let elementRect = obj.element.getBoundingClientRect();
        let placeholderRect = obj.placeholder.getBoundingClientRect();
        let containerRect = obj.container.getBoundingClientRect();

        if (isContainerBottomReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
            setAffixBottom(obj);
            obj.isAffix = false;
        } else if (isAffixTopReached(containerRect, elementRect, placeholderRect, obj.offset, obj.isCentered, obj.isBottom)) {
            setAffix(obj);
            obj.isAffix = true;
        } else {
            resetElement(obj);
            obj.isAffix = false;
        }
    });
}

function setAffix(obj) {
    let placeholderRect = obj.placeholder.getBoundingClientRect();

    addClass('is-affix', obj.element);
    trigger(EVENTS.AFFIX, obj.element);

    let offset = obj.element.dataset.affixOffset || obj.offset;

    Object.assign(obj.element.style, {
        position: 'fixed',
        top: `${offset}px`,
        left: `${placeholderRect.left}px`,
        width: `${placeholderRect.width}px`,
        ...obj.isCentered ? {
            top: `calc(50% + ${offset}px)`,
            transform: 'translateY(-50%)'
        } : null,
        ...obj.isBottom ? {
            top: `calc(100% - ${offset}px)`,
            transform: 'translateY(-100%)'
        } : null
    });

    obj.isAffix = true;
}

function setAffixBottom(obj) {
    let placeholderRect = obj.placeholder.getBoundingClientRect();
    let elementRect = obj.element.getBoundingClientRect();
    let containerRect = obj.container.getBoundingClientRect();

    removeClass('is-affix', obj.element);
    trigger(EVENTS.DETACH, obj.element);

    Object.assign(obj.element.style, {
        position: 'relative',
        top: `${containerRect.height - (placeholderRect.top - containerRect.top) - elementRect.height}px`,
        ...obj.isBottom ? {
            top: 0,
        } : null,
        left: '',
        transform: 'translateZ(0)',
    });

    obj.isAffix = false;
}

function isElementTooBig({element, offset}) {
    return element.getBoundingClientRect().height + offset <= window.innerHeight
}

function isContainerBottomReached(containerRect, elementRect, placeholderRect, offset, isCentered = false, isBottom = false) {
    if (isCentered) {
        return containerRect.top + containerRect.height - offset - elementRect.height - (window.innerHeight / 2) + (elementRect.height / 2) <= 0
    } else if (isBottom) {
        return placeholderRect.bottom + offset - containerRect.height - window.innerHeight + elementRect.height >= 0
    } else {
        return containerRect.top + containerRect.height - offset - elementRect.height <= 0;
    }
}

function isAffixTopReached(containerRect, elementRect, placeholderRect, offset, isCentered = false, isBottom = false) {
    if (isCentered) {
        return placeholderRect.top - offset - (window.innerHeight / 2) + (elementRect.height / 2) <= 0
    } else if (isBottom) {
        return placeholderRect.top - offset - (window.innerHeight) + (elementRect.height) >= 0
    } else {
        return placeholderRect.top - offset <= 0;
    }
}

function isElementInDom(el) {
    return document.body.contains(el);
}