import "@elements/dom-utils/polyfills/ie";
import {onFind} from "@elements/init-modules-in-scope";
import {find,on} from "@elements/dom-utils";

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element)
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import Alert from 'bootstrap/js/dist/alert';
onFind('.alert',function (element) {
    new Alert(element)
});

import * as toc from './toc';
toc.init({
    linkClass: 'visually-hidden-focusable'
});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init({preventNative: true});

import * as nav from './nav';
nav.init();

import * as affix from '@elements/affix';

let affixNavbar = find('.js-affix-navbar');
let options = {mediaQuery: '(max-width: 767px)'};
if (affixNavbar.getAttribute('data-affix-navbar-on-desktop') === 'true'){
    options = {};
}
affix.init(options,{
    base: '.js-affix-navbar',
    placeholder: '.js-affix-navbar__placeholder',
    container: '.js-affix-navbar__container'
});

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as parallax from './parallax';
parallax.init();

import * as darkpage from './darkpage';
darkpage.init();

import * as message from './message';
message.init();

import * as ajaxModal from './ajax-modal';
ajaxModal.init();

import * as loadCart from './load-cart';
loadCart.init();

import * as dragScrolling from './drag-scrolling';
dragScrolling.init();

import * as centerActiveTab from './center-active-tab';
centerActiveTab.init();

import * as cookieInformationPlaceholder from './cookieinformation-placeholder';
cookieInformationPlaceholder.init();

import * as tracking from '@elements/tracking';
tracking.initGtmEventTracking({debug: true});

import './common-ticket-configuration';

import * as profileDropdown from './profile-dropdown';
profileDropdown.init();

import * as percentageIndicator from './percentage-indicator';
percentageIndicator.init();

import * as microanimations from './microanimations';
microanimations.init();

import * as microanimationsMorphingModal from './microanimations-morphingmodal';
microanimationsMorphingModal.init();